///fonts///

$font-family: "Open Sans", Arial, sans-serif;
$light: 300;
$normal: 400;
$bold: 700;
$font-size-small: 12px;
$font-size: 14px;
$font-size-bigger: 16px;
$font-size-big: 18px;
$font-size-medium: 20px;
$font-size-title: 24px;

///colors///

$main-color: #FA5252;
$dark-color: #002F48;
$black-blue-text: #0a112f;
$violet: #845EF7;
$gray: #585860;

$font-main-color: #000;

///screen///
$screen-xxs:                  320px;
$screen-xs:                   480px;
$screen-sm:                   768px;
$screen-md:                   992px;
$screen-lg:                   1200px;
$screen-xl:                   1920px;

//retina

$retina: (min-resolution: 144dpi), (min-resolution: 1.5dppx);

