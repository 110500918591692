// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$chrome-name: 'chrome';
$chrome-x: 0px;
$chrome-y: 0px;
$chrome-offset-x: 0px;
$chrome-offset-y: 0px;
$chrome-width: 64px;
$chrome-height: 64px;
$chrome-total-width: 132px;
$chrome-total-height: 132px;
$chrome-image: '../img/sprite-png.png';
$chrome: (0px, 0px, 0px, 0px, 64px, 64px, 132px, 132px, '../img/sprite-png.png', 'chrome', );
$github-logo-name: 'github-logo';
$github-logo-x: 68px;
$github-logo-y: 0px;
$github-logo-offset-x: -68px;
$github-logo-offset-y: 0px;
$github-logo-width: 64px;
$github-logo-height: 64px;
$github-logo-total-width: 132px;
$github-logo-total-height: 132px;
$github-logo-image: '../img/sprite-png.png';
$github-logo: (68px, 0px, -68px, 0px, 64px, 64px, 132px, 132px, '../img/sprite-png.png', 'github-logo', );
$placeholder-name: 'placeholder';
$placeholder-x: 0px;
$placeholder-y: 68px;
$placeholder-offset-x: 0px;
$placeholder-offset-y: -68px;
$placeholder-width: 64px;
$placeholder-height: 64px;
$placeholder-total-width: 132px;
$placeholder-total-height: 132px;
$placeholder-image: '../img/sprite-png.png';
$placeholder: (0px, 68px, 0px, -68px, 64px, 64px, 132px, 132px, '../img/sprite-png.png', 'placeholder', );
$spritesheet-width: 132px;
$spritesheet-height: 132px;
$spritesheet-image: '../img/sprite-png.png';
$spritesheet-sprites: ($chrome, $github-logo, $placeholder, );
$spritesheet: (132px, 132px, '../img/sprite-png.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
