@import "blocks/variables.scss";
@import "blocks/mixins.scss";
@import "blocks/normalize.scss";
@import "blocks/sprite-png.scss";
@import "blocks/fonts.scss";

// PAGE COMPONENTS
@import "blocks/header.scss";
@import "blocks/promo.scss";
@import "blocks/navbar.scss";

body {
  font-family: "Golos Text", system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.mr-2 {
  margin-right: 15px;
}
.icon {
  display: inline-block;
}

.icon-chrome {
  @include sprite($chrome);
}
.icon-placeholder {
  @include sprite($placeholder);
}

.section-type {
  margin: 48px 0px;
}

.custom-btn {
  background: $main-color;
  border-color: $main-color;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  padding: 19px 45px;

  &:hover {
    background: $main-color;
    border-color: $main-color;
    opacity: 0.7;
  }
}

// Subheader.html
.subheader {
  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 68px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: $black-blue-text;
  }
  p.description {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: -0.01em;
    color: $gray;
    margin-bottom: 40px;
  }
}

.product-hunt-btn {
  cursor: pointer;
  background-color: transparent;
  padding: 0.5875rem 0.9375rem;
  font-size: 1rem;
  border-radius: 0.625rem;
  border: 1px solid rgba(39, 40, 42, 0.3) !important;
  border-color: #fa5252 !important;
  font-weight: 600 !important;
  line-height: 1 !important;
  color: #fa5252 !important;
  display: inline-flex;
  align-items: flex-start;
  flex-direction: column;
  flex-direction: row;
  padding: 15px 60px 15px 15px;
  svg {
    margin-right: 10px;
  }

  .p-hunt-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
  .ph-title {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 700;
    font-size: 9px !important;
    line-height: 10px;
    margin-bottom: 3px;
    margin-top: 3px;
  }
  .ph-subtitle {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 700;
    font-size: 16px !important;
    line-height: 18px;
  }
}

// call-actions.html
section {
  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 110%;
    text-align: center;
    letter-spacing: -0.02em;
    color: $black-blue-text;

    span {
      color: $violet;
    }
  }
  .t-description {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.01em;
    color: $gray;
  }
}

// section-3.html
.section-3 {
  margin-left: 16px;
  margin-right: 16px;
  .section-3-feature {
    border-radius: 24px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #ffffff;
    padding: 32px 16px;
    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 150%;
      margin-bottom: 16px;
    }
    .description {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      letter-spacing: -0.02em;
      color: #ffffff;
    }
  }

  .section-3-feature-bottom {
    background: linear-gradient(90deg, #845ef7 0%, #ff6b6b 100%);
    border-radius: 18px;
    padding: 24px 0px;

    svg {
      margin-bottom: 15px;
    }
  }
}

// price.html
.price-table {
  .price-hr {
    display: flex;
    border: 2px solid #f4f4f5;
  }
  .price-table-header {
    display: flex;
  }

  .price-header-icon {
    margin-right: 8px;
  }
  .card {
    padding: 24px 48px;
  }

  .card-title {
    margin-top: 24px;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    letter-spacing: -0.01em;
  }
  .card-body {
    padding: 0;
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 160%;
      letter-spacing: -0.02em;
      font-feature-settings: "ss03" on;

      color: #585860;
    }
    ul li {
      margin-bottom: 8px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 160%;
      color: $gray;
      svg {
        margin-right: 8px;
      }
    }
    ul li.disabled {
      color: #afafaf;
    }
  }
  .dark-card {
    border-color: $dark-color;
    color: #fff;
    .card-body {
      padding: 0;
      p {
        color: #fff;
      }
      ul li {
        color: #fff;
      }
      .price-hr {
        opacity: 0.1;
      }
    }
  }
}
// complexity.html


// footer 
footer {
  padding: 48px 0px;
  background-color: #fff;
  border-top: 1px solid #e7e7e7;

}

// Author 
.author {
  background: #f9f9f9;
  box-shadow: 0px 4px 200px rgba(232, 249, 247, 0.2);
  padding: 48px 0px;
  .bio {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
     li {
      text-align: left;
      color: #585860;
      margin-bottom: 20px;
    }
  }
  hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

  .bio-desc {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;

  }
  h2 {
    font-style: normal;
font-weight: 600;
font-size: 30px;
line-height: 130%;

  }
  .bio-thx {
    font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 17px;
text-align: center;

  }
}