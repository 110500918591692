@import "./variables.scss";

.login-section {
    .btn-link {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #ffffff;
        text-decoration: none;
        align-items: center;
        display: flex;
        &:hover {
            cursor: pointer;
            color: #ffffff;
            opacity: 0.7;
        }
    }
    .btn-primary {
        background: $main-color;
        border-color: $main-color;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;

        &:hover {
            background: $main-color;
            border-color: $main-color;
            opacity: 0.7;
        }
    }

}

.bg-dark {
    background-color: $dark-color !important;
}