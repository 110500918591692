.promotion {
  background: linear-gradient(180deg,
      rgb(132, 94, 247) 0%,
      rgb(255, 107, 107) 100%);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  span.badge {
    margin-right: 10px;
  }

  p {
    color: #fff;
  }

  p a {
    color: #fff;
    margin-left: 10px;
    white-space: nowrap;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }

  }
 .new {
  border-radius: 16px;
  padding: 0px 30px 0px;

 }
}